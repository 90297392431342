import { useEffect, useState, useContext } from "react";
import AuthenticationProvider from "../contexts/AuthenticationContext";

export function useSendPostRequest(URL, payload) {
  const { user, backendURL } = useContext(AuthenticationProvider);

  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(
    function () {
      async function fetchData() {
        const finalPayload = { ...payload, user: user };
        console.log(finalPayload);
        console.log(payload);

        try {
          const response = await fetch(`${backendURL}${URL}/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(finalPayload),
          });

          const data = await response.json();

          if (!response.ok) {
            console.log(data);
            throw new Error(data["error"]);
          }
          console.log(data);
          setErrorMsg(null);
          setSuccessMsg(data["successMsg"] || "Request made successfully!");
          setData(data["responseData"]);
        } catch (error) {
          setErrorMsg(error.message);
          console.error(error);
        } finally {
          setLoading(null);
        }
      }
      fetchData();
    },
    [URL, backendURL, user]
  );
  console.log(data);
  return { data, successMsg, loading, errorMsg };
}
