import useWebSocket, { ReadyState } from "react-use-websocket";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import SpinnerFullPage from "../FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { default as ApexStockChart } from "react-apexcharts";
import styles from "./ChartMain.module.css";
import { useDarkMode } from "../../contexts/DarkModeContext";

import emptyStar from "../../Images/empty-star.png";
import fullStar from "../../Images/star.png";
import Table from "./Table";
import ChartProvider from "../../contexts/ChartContext";
import AuthenticationProvider from "../../contexts/AuthenticationContext";

const API_TOKEN = "660984ab5147b7.31461482";
const WS_URL = `wss://ws.eodhistoricaldata.com/ws/us?api_token=${API_TOKEN}`;

function Chart() {
  const { slug } = useParams();
  const { user } = useContext(AuthenticationProvider);
  const userId = user.user_id;
  const { data, errorMsg, loading } = useSendGetRequest(`chart/${slug}/${userId}/`);
  const { constants } = useDarkMode();

  const { error, handleWatchlistChange } = useContext(ChartProvider);

  const [stockData, setStockData] = useState(null);
  const [sentimentScoreData, setSentimentScoreData] = useState(null);
  const [watchlist, setWatchlist] = useState(null);
  const [currentStockPrice, setCurrentStockPrice] = useState(null);

  useEffect(
    function () {
      setStockData(data?.stockData);
      setWatchlist(data?.onWatchList && true);
      setSentimentScoreData(data?.sentimentScore);
    },
    [data]
  );
  // useEffect(
  //   function () {

  //   },
  //   [data, slug]
  // );

  // CONNECT TO EODHD REAL TIME DATA USING WEB SOCKETS
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_URL, {
    share: false,
    shouldReconnect: () => true,
  });

  //   SUBSCRIBE TO RECEIVE NEW DATA
  useEffect(() => {
    if (readyState === ReadyState.OPEN && stockData) {
      sendJsonMessage({
        action: "subscribe",
        symbols: slug,
      });
    }
  }, [readyState]);

  useEffect(
    function () {
      if (stockData) {
        console.log(+stockData[0].close.toFixed(2));
        setCurrentStockPrice(+stockData[0].close.toFixed(2));
      }
    },
    [stockData]
  );

  // Run when a new WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    if (!lastJsonMessage) return;
    setCurrentStockPrice(+JSON.stringify(lastJsonMessage.p));
  }, [lastJsonMessage]);

  async function addToWatchlist() {
    setWatchlist((current) => !current);
    handleWatchlistChange(slug);
  }

  if (loading || !stockData) return <SpinnerFullPage />;
  if (errorMsg || error) return <ErrorMessage errorMessage={errorMsg} />;

  const max = new Date(); // Current timestamp
  const min = new Date(new Date().setDate(max.getDate() - 30));

  const range = max - min;
  const options = {
    options: {
      chart: {
        type: "line",

        animations: {
          enabled: false,
        },

        background: constants.dark,
        foreColor: constants.light,

        events: {
          beforeZoom: function (ctx) {
            // we need to clear the range as we only need it on the iniital load.
            ctx.w.config.xaxis.range = undefined;
          },
        },

        toolbar: {
          show: true,
          tools: {
            download: false, //
            selection: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
      },
      stroke: {
        show: true,
        curve: "smooth",

        width: 2,
      },

      grid: {
        show: false,
      },
      markers: 0,
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
        tickPlacement: "on",
        zoomedArea: {
          fill: {
            color: "#6b71f2",
            opacity: 0.4,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: `Chart for ${slug} ticker`,
        align: "center",
      },
      colors: ["#0ff2b2"],
      xaxis: {
        range: range,
        type: "datetime",
        labels: {
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return "$" + value.toFixed(0);
          },
        },
      },

      tooltip: {
        theme: "dark",
        y: {
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return `$${value.toFixed(2)}`;
          },
        },
      },
    },
    series: [
      {
        name: `Closing price`,
        data: stockData.map((stockData) => ({
          x: new Date(stockData.time),
          y: stockData.close,
        })),
      },
    ],
  };

  return (
    <div className={styles.chartPageWrapper}>
      <h1>Chart for {slug} ticker</h1>
      <img
        title={`Add "${slug}" to Watchlist`}
        src={watchlist ? fullStar : emptyStar}
        width="30"
        alt="watchlist"
        id={styles.watchlist}
        onClick={addToWatchlist}
      />
      <div className={styles.currentPriceBox}>
        <p>${currentStockPrice?.toFixed(2)}</p>
        <h3>Current price</h3>
      </div>
      <div id={styles.chart}>
        <ApexStockChart
          options={options.options}
          series={options.series}
          type="line"
          height="300px"
        />
      </div>
      <div className={styles.sentimentScore}>
        <h1>Sentiment Score Data</h1>
        <Table data={sentimentScoreData} />
      </div>
    </div>
  );
}

export default Chart;
