import { useContext, useEffect, useState } from "react";

import SmallLoader from "../components/SmallLoader/SmallLoader";
import ErrorMessage from "./ErrorMessage/ErrorMessage";

import AuthenticationProvider from "../contexts/AuthenticationContext";

import styles from "./SearchField.module.css";
import { Link } from "react-router-dom";

function SearchField() {
  const { backendURL } = useContext(AuthenticationProvider);

  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(
    function () {
      const controller = new AbortController();
      async function fetchData() {
        console.log(search);
        if (search.slice().length > 7) {
          setErrorMsg("There are no tickers longer then 7 characters.");
          return;
        }

        try {
          setLoading(true);
          setErrorMsg("");
          const response = await fetch(`${backendURL}search-for-ticker/${search}/`);
          const apiData = await response.json();

          if (!response.ok) {
            if (apiData["errorMsg"]) {
              throw new Error(apiData["errorMsg"]);
            } else {
              throw new Error("Error happened while fetching");
            }
          }
          console.log(apiData);
          setData(apiData["ticker"]);
          setErrorMsg(null);
        } catch (error) {
          setErrorMsg(error.message);
          // setData(null);
        } finally {
          setLoading(false);
        }
      }
      if (search) fetchData();
      else {
        setData(null);
        setErrorMsg(null);
      }
      return function () {
        controller.abort();
      };
    },

    [search]
  );

  return (
    <div className={styles.search}>
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search for ticker..."
      />
      {loading && <SmallLoader />}
      {!loading && (
        <>
          <div className={styles.errorMsg}>
            {errorMsg && !data && <ErrorMessage errorMessage={errorMsg} />}
          </div>
          {data && (
            <div className={styles.searchResults}>
              <Link to={`/chart/${data}`}>{data}</Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SearchField;
