import { useNavigate } from "react-router-dom";
import styles from "./PaymentSuccess.module.css";
import { useEffect, useState } from "react";

function PaymentMessages({ msg }) {
  const navigate = useNavigate();
  const [durationInSeconds, setDurationInSeconds] = useState(7);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDurationInSeconds((prev) => prev - 1);
      if (durationInSeconds === 0) {
        navigate("/");
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [durationInSeconds, navigate]);

  return (
    <div className={styles.pageWrapper}>
      <h1>{msg}</h1>
      <p>
        You will be redirected to home page in <span id={styles.seconds}> {durationInSeconds}</span>{" "}
      </p>
    </div>
  );
}

export default PaymentMessages;
