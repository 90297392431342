import styles from "./SmallLoader.module.css";

function SmallLoader() {
  return (
    <div class={styles.loadingContainer}>
      <button>
        <div class={styles.buttonLoader}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
    </div>
  );
}

export default SmallLoader;
