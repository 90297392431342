import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Register from "./pages/Register/Register.js";
import Login from "./pages/Login/Login.js";

import { AuthenticationProvider } from "./contexts/AuthenticationContext.js";
import Home from "./pages/Home/Home.js";
import PaymentMessages from "./pages/Payment/PaymentSuccess.js";
import ResetPassword from "./pages/ResetPassword/ResetPassword.js";
import SendEmailToResetPassword from "./pages/ResetPassword/SendEmailToResetPassword.js";
import Error404 from "./pages/Errors/Error404.js";
import Chart from "./components/Chart/ChartMain.js";
import Navbar from "./components/Navbar/Navbar.js";
import { DarkModeProvider } from "./contexts/DarkModeContext.js";
import { ChartProvider } from "./contexts/ChartContext.js";
import WatchList from "./pages/WatchList/WatchList.js";
import UserProfile from "./pages/UserProfile/UserProfile.js";
import useSendGetRequest from "./hooks/useSendGetRequest.js";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";

function App() {
  const [permission, setPermission] = useState();
  const userId = localStorage.getItem("authTokens")
    ? jwtDecode(localStorage.getItem("authTokens"))["user_id"]
    : null;

  const { data } = useSendGetRequest(`check-subscription/${userId}/`);

  useEffect(
    function () {
      if (data) {
        setPermission(true);
      }
    },
    [data]
  );

  return (
    <DarkModeProvider>
      <Router>
        <AuthenticationProvider>
          <ChartProvider>
            <Navbar />
            <div className="app-content-wrapper">
              <Routes>
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/login" element={<Login />} />
                <Route path="/" element={<Home permission={permission} />} />
                <Route
                  exact
                  path="/payment-success"
                  element={
                    <PaymentMessages
                      msg={
                        "Payment completed successfully! You can now experience all features of Mithria website!"
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/payment-cancel"
                  element={
                    <PaymentMessages
                      msg={
                        "Subscription canceled successfully! You will have access to the website until subscription expires."
                      }
                    />
                  }
                />
                <Route exact path="/reset-password" element={<ResetPassword />} />
                <Route exact path="/send-reset-password" element={<SendEmailToResetPassword />} />

                {/* IF USER PAID AND LOGGED IN HE CAN ACCESS THESE 3 PAGES */}
                {permission && (
                  <>
                    <Route exact path="/chart/:slug" element={<Chart />} />
                    <Route exact path="/watchlist" element={<WatchList />} />
                    <Route exact path="/user-profile" element={<UserProfile />} />
                  </>
                )}
                <Route path="*" element={<Error404 />} />
              </Routes>
            </div>
          </ChartProvider>
        </AuthenticationProvider>
      </Router>
    </DarkModeProvider>
  );
}

export default App;
