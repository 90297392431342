import { useContext } from "react";
import AuthenticationProvider from "../../contexts/AuthenticationContext";
import axios from "axios";
import { useSendPostRequest } from "../../hooks/useSendPostRequest";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import Login from "../Login/Login";
import styles from "./Home.module.css";

function Home({ permission }) {
  const { user, backendURL } = useContext(AuthenticationProvider);

  const { loading, errorMsg } = useSendPostRequest("user");

  function createCheckoutSession() {
    axios
      .post(`${backendURL}create-checkout-session/`, JSON.stringify({ data: user }))
      .then((res) => {
        const sessionUrl = res["data"]["sessionUrl"];
        window.location.href = sessionUrl;
      });
  }

  if (!user) return <Login />;
  if (loading) return <SpinnerFullPage />;
  return (
    <div className={styles.homeBackground}>
      <h1 className={styles.welcomeMsg}>
        Hello <span>{user["username"]}</span>, how are you today?
      </h1>
      <div className={styles.paidBtns}>
        {!permission && (
          <>
            <h2>To continue using website please complete payment.</h2>
            <button onClick={createCheckoutSession} className="standard-btn">
              Pay now!
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
