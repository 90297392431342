import React, { useState, useContext } from "react";

import PasswordInput from "../../components/ReusableComponents/PasswordField/PasswordInput";
import styles from "./ResetPassword.module.css";
import AuthenticationContext from "../../contexts/AuthenticationContext";

function ResetPassword() {
  const { backendURL } = useContext(AuthenticationContext);
  const [newPasswordValue, setNewPasswordValue] = useState();
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    const email = localStorage.getItem("email_to_reset_password");

    try {
      console.log(newPasswordValue);
      const response = await fetch(`${backendURL}change_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,

          new_password: newPasswordValue,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data ? data : "Error happened, please try again!");

      setError(null);
      setSuccessMsg("Your password is changed successfully!");
    } catch (error) {
      setError(error.message);
      setSuccessMsg(null);
    }
  }

  return (
    <div className={styles.pageWrapper}>
      <h1>Reset Password</h1>
      <form className={styles.resetPasswordForm} onSubmit={handleSubmit}>
        <div>
          <label id={styles.newPasswordLabel}>New Passowrd</label>
          <br></br>
          <br></br>
          <PasswordInput
            eyeColor="#000"
            key={2}
            passwordValue={newPasswordValue}
            handleChange={(e) => setNewPasswordValue(e.target.value)}
          />
        </div>
        <div className="msg">
          {error && <p id="error"> {error}</p>}
          {successMsg && <p id="success"> {successMsg}</p>}
        </div>
        <button id="reset-password" className="standard-btn">
          Reset password
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
