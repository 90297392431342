import { useContext } from "react";

// COMPONENTS
import PasswordInput from "../../components/ReusableComponents/PasswordField/PasswordInput";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";

// CONTEXT
import AuthenticationProvider from "../../contexts/AuthenticationContext";

// STYLE
import styles from "./Login.module.css";
import { Link } from "react-router-dom";

function Login() {
  const { loadingLogin, errorLogin, loginUser } = useContext(AuthenticationProvider);

  return (
    <div className={styles.login}>
      {loadingLogin && <SpinnerFullPage />}
      {!loadingLogin && (
        <>
          <h1>Welcome back, Login!</h1>
          <form onSubmit={loginUser} className={styles.loginForm}>
            <div>
              <label>Username</label>
              <input type="text" placeholder="Username" name="username" />
            </div>

            <div>
              <label>Password</label>
              <PasswordInput eyeColor="black" />
            </div>

            {errorLogin && <ErrorMessage errorMessage={errorLogin} />}
            <p>
              Don't have an account? <Link to="/register">Register!</Link>
            </p>
            <Link className="forgot-password" to="/send-reset-password">
              Forgot password?
            </Link>
            <button type="submit" className="standard-btn">
              Login
            </button>
          </form>
        </>
      )}
    </div>
  );
}

export default Login;
