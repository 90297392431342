import { useContext } from "react";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import styles from "./WatchList.module.css";
import AuthenticationProvider from "../../contexts/AuthenticationContext";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { Link } from "react-router-dom";

function WatchList() {
  const { user } = useContext(AuthenticationProvider);
  const { data, errorMsg, loading } = useSendGetRequest(`get-watchlist-data/${user?.user_id}/`);

  if (loading || !data) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;

  if (data["watchlist"].length === 0)
    return (
      <div className={styles.watchlistWrapper}>
        <h1>Nothing on the watchlist yet</h1>
      </div>
    );
  return (
    <div className={styles.watchlistWrapper}>
      <h1>Stefan, this is your watchlist</h1>
      <div className={styles.watchlist}>
        {data["watchlist"].map((ticker) => (
          <Link to={`/chart/${ticker.ticker}`}>
            <div className={styles.watchlistTicker}>
              <div className={styles.tickerDetails}>
                <h3>{ticker.ticker}</h3>
                <p id={styles.date}>
                  On your watchlist since:
                  <span> {new Date(ticker.date).toLocaleDateString("en-US")} </span>
                </p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30px"
                viewBox="0 -960 960 960"
                width="30px"
                fill="red"
              >
                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
              </svg>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default WatchList;
