import { useContext } from "react";
import { Link } from "react-router-dom";

import styles from "./Navbar.module.css";
import LogoDark from "../../Images/dark-mode-logo.png";
import LogoLight from "../../Images/light-mode-logo.png";

import AuthenticationProvider from "../../contexts/AuthenticationContext";
import { useDarkMode } from "../../contexts/DarkModeContext";
import SearchField from "../SearchField";

function Navbar() {
  const { logoutUser, user } = useContext(AuthenticationProvider);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  if (!user) return null;
  return (
    <>
      <div className={styles.navbar}>
        <SearchField />
      </div>
      <div className={styles.sidebar}>
        <Link to={"/"}>
          <div className={styles.logoDetails}>
            <img
              src={isDarkMode ? LogoLight : LogoDark}
              className="bx bxl-codepen icon"
              alt="logo"
            />
          </div>
        </Link>
        <ul className={styles.navList}>
          <li onClick={toggleDarkMode}>
            <input
              type="checkbox"
              className={styles.checkbox}
              id={styles.checkbox}
              checked={isDarkMode}
              onChange={toggleDarkMode}
            />

            <label htmlFor="checkbox" className={styles.checkboxLabel}>
              <i className="bx bxs-moon"></i>
              <i className="bx bxs-sun"></i>
              <span className={styles.ball}></span>
            </label>
          </li>
          <li>
            <Link to="/">
              <i className="bx bx-grid-alt"></i>
              <span className={styles.linksName}>Dashboard</span>
            </Link>
            <span className={styles.tooltip}>Dashboard</span>
          </li>
          <li>
            <Link to={"/user-profile"}>
              <i className="bx bx-user"></i>
              <span className={styles.linksName}>User</span>
            </Link>
            <span className={styles.tooltip}>User</span>
          </li>
          <li>
            <Link to="/watchlist">
              <i className="bx bx-heart"></i>
              <span className={styles.linksName}>Watchlist</span>
            </Link>
            <span className={styles.tooltip}>Watchlist</span>
          </li>

          <li className={styles.profile} onClick={() => logoutUser()}>
            <div className={styles.profileDetails}>
              <i className="bx bx-export"></i>
              <div className={styles.nameJob}>
                <div className={styles.name}>Logout</div>
              </div>
            </div>
            <i className="bx bx-log-out" id={styles.logOut}></i>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
