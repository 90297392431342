import { useEffect, useState } from "react";
import styles from "./Table.module.css";
import { Link } from "react-router-dom";
import SpinnerFullPage from "../FullPageLoader/SpinnerFullPage";

function Table({ data }) {
  const numberOfRowsPerTablePage = 10;
  const [sentimentScoreData, setSentimentScoreData] = useState();

  useEffect(
    function () {
      setSentimentScoreData(data.slice(0, numberOfRowsPerTablePage));
    },
    [data]
  );
  function handleNextTable(num) {
    setSentimentScoreData(
      data.slice((num - 1) * numberOfRowsPerTablePage, num * numberOfRowsPerTablePage)
    );
  }
  const numOfPaginationBtns = Math.ceil(data.length / numberOfRowsPerTablePage);
  if (!sentimentScoreData) return <SpinnerFullPage />;
  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr>
            <th>News Date</th>
            <th>News URL</th>
            <th>News Headline</th>
            <th>Sentiment Score</th>
          </tr>
        </thead>
        <tbody>
          {sentimentScoreData.map((row) => (
            <tr>
              <td width="10px">{new Date(row.sentiment_score_date).toLocaleDateString("en-US")}</td>
              <td width="0px">
                <Link to={row.article_url} target="_blank">
                  {row.article_url?.substring(0, 75)}...
                </Link>
              </td>
              <td width="10px">{row.title?.substring(0, 75)}...</td>
              <td width="10px">
                <span>{row.sentiment_score}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        {Array.from({ length: numOfPaginationBtns }, (_, i) => (
          <button onClick={() => handleNextTable(i + 1)} className={styles.paginator}>
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Table;
