import { createContext, useContext, useState } from "react";

import AuthenticationProvider from "../contexts/AuthenticationContext";

const ChartContext = createContext();

export default ChartContext;

export const ChartProvider = ({ children }) => {
  const { user, backendURL } = useContext(AuthenticationProvider);
  const [error, setError] = useState(null);

  const handleWatchlistChange = async function (ticker) {
    try {
      const response = await fetch(`${backendURL}handle-watchlist-change/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ticker,
          user,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(
          data["errorMsg"]
            ? data["errorMsg"]
            : `Error happened while trying to add "${ticker}" ticker on your watchlist`
        );
      }
      setError(null);
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };
  // FINAL DATA
  const contextData = {
    error,
    handleWatchlistChange,
  };

  return <ChartContext.Provider value={contextData}>{children}</ChartContext.Provider>;
};
