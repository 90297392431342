import { useContext, useState } from "react";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import styles from "./UserProfile.module.css";
import AuthenticationContext from "../../contexts/AuthenticationContext";
import { Link, useNavigate } from "react-router-dom";

function UserProfile() {
  const { user, backendURL } = useContext(AuthenticationContext);
  const { data, loading, errorMsg } = useSendGetRequest(`user-profile/${user?.user_id}`);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  async function cancelSubscription() {
    try {
      setIsLoading(true);
      const response = await fetch(`${backendURL}cancel-subscription/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: user }),
      });

      if (!response.ok) {
        console.log(data);
        throw new Error("Something went wrong while trying to cancel subscription.");
      }
      navigate("/payment-cancel");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  console.log(data);
  if (loading || isLoading) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;
  return (
    <div className={styles.profileWrapper}>
      <div className={styles.profile}>
        <div className={styles.header}>
          <h1>Profile</h1>
          <div className={styles.actionBtns}>
            <button className="standard-btn" onClick={() => navigate("/send-reset-password")}>
              Reset password
            </button>
            <button className="danger-btn" onClick={cancelSubscription}>
              Cancel subscription
            </button>
          </div>
        </div>
        <div className={styles.body}>
          <div>
            <h3 className={styles.username}>{data.user.username}</h3>
            <p>{data.user.email}</p>
          </div>
          <p id={styles.joined}>
            Joined: {new Date(data.user.date_joined).toLocaleDateString("en-US")}
          </p>
          <Link to={"/watchlist"} id={styles.numOfTickers}>
            {data.watchlist} {data.watchlist === 1 ? "ticker" : "tickers"} on watchlist
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
