import React, { useState, useContext } from "react";
import styles from "./ResetPassword.module.css";
import AuthenticationContext from "../../contexts/AuthenticationContext";

function SendEmailToResetPassword() {
  const { backendURL } = useContext(AuthenticationContext);
  const [email, setEmail] = useState();

  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    localStorage.setItem("email_to_reset_password", email);
    console.log(email);
    try {
      const response = await fetch(`${backendURL}password_reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Error occured");
      }
      setError(null);
      setSuccessMsg("Email confirmation has been sent to your email!");
    } catch {
      setError(error.message);
      setSuccessMsg(null);
    }
  }

  return (
    <div className={styles.pageWrapper}>
      <h1>Reset Password</h1>
      <form className={styles.resetPasswordForm} onSubmit={handleSubmit}>
        <div>
          <label>Your account email</label>
          <input
            placeholder="example@gmail.com"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="msg">
          {error && <p id="error">{error}</p>}
          {successMsg && <p id="success">{successMsg}</p>}
        </div>
        <button id="reset-password" className="standard-btn">
          Send email
        </button>
      </form>
    </div>
  );
}

export default SendEmailToResetPassword;
